import { mapActions, mapState } from "vuex";
const mixPanel = {
  data() {
    return {};
  },
  methods: {
    mixPanelCreateUser(payload) {
      const { name, email, event } = payload;

      let { name: loginName, email: loginEmail } = this.loginUser;

      const userEmail = email ? email : loginEmail;
      const userName = name ? name : loginName;
      this.$Mixpanel.people.set(
        userEmail,
        {
          $name: userName,
          $email: userEmail,
          $created: new Date().toISOString(),
        },
        {
          $ip: this.ipAddress,
        }
      );
      const data = { email, event };
      this.mixPanelTrack(data);
    },
    mixPanelTrack(payload) {
      const { event, email } = payload;
      const userEmail = email
        ? email
        : this.loginUser
        ? this.loginUser.email
        : "";
      this.$Mixpanel.track(event, {
        distinct_id: userEmail,
        ip: this.ipAddress,
        ...payload,
      });
    },
  },
  computed: {
    ...mapState("userManagement", ["currentUser", "ipAddress"]),
    loginUser() {
      return this.currentUser;
    },
  },
};

export default mixPanel;
