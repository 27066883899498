<template>
  <div id="body">
    <div id="navbar-container">
      <Navbar />
    </div>

    <v-dialog v-model="learn_dialog" max-width="550">
      <div id="learn-more-section">
        <div id="learn-more-header">Get Full Access</div>

        <div id="learn-more-content">
          <div class="learn-more-info">
            <div class="learn-more-question">Why?</div>
            <div class="learn-more-answer">
              Our expert marketplace is restricted to the general public. This
              is mainly to protect our experts’ privacy and their time.
            </div>
          </div>

          <div class="learn-more-info">
            <div class="learn-more-question">How do I unlock full access?</div>
            <div class="learn-more-answer">
              Pay a one-time fee of $500 to unlock full access to the
              marketplace, find a match and meet with the experts immediately.
              We will provide you with priority support and refund you if we are
              unable to find an expert that fit your needs.
            </div>
          </div>

          <div class="learn-more-info">
            <div class="learn-more-question">What can I do otherwise?</div>
            <div class="learn-more-answer">
              If you are not ready to proceed at this time, you can add members
              to your virtual team and join the waitlist. We will reach out to
              you if any expert indicates interest in your product.
            </div>
          </div>
        </div>

        <div id="learn-more-bottom-panel">
          <button
            type="button"
            class="grupa-grey-black-btn"
            @click="learn_dialog = false"
          >
            Close
          </button>
          <button type="button" class="grupa-blue-btn" @click="handleProceed">
            Get Full Access
          </button>
        </div>
      </div>
    </v-dialog>

    <div id="content-wrapper">
      <div id="info-section">
        <div id="info-section-lock-div">
          <img id="info-section-lock-img" src="/img/Locked.svg" alt="" />
        </div>
        <div id="info-section-content">
          <div id="info-section-title">
            <span>Get full access to the Expert marketplace for $500</span>
          </div>
          <div id="info-section-marked-content-section">
            <div class="info-section-marked-content">
              <img class="info-section-mark" src="/img/mark_green.svg" alt="" />
              <p class="info-section-marked-text">Meet experts immediately</p>
            </div>
            <div class="info-section-marked-content">
              <img class="info-section-mark" src="/img/mark_green.svg" alt="" />
              <p class="info-section-marked-text">Get Priority support</p>
            </div>
            <div class="info-section-marked-content">
              <img class="info-section-mark" src="/img/mark_green.svg" alt="" />
              <p class="info-section-marked-text">
                Refundable if not satisfied
              </p>
            </div>
          </div>
        </div>
        <div id="info-section-button-container">
          <button
            type="button"
            class="grupa-blue-btn"
            @click.stop="learn_dialog = true"
          >
            Learn More
          </button>
        </div>
      </div>
      <div id="banner-wrapper">
        <div id="banner-title">Preview your potential team</div>
        <div id="banner-content">
          We have curated a list of experts that best fit your needs. Take a
          look at the potential team you could build.
        </div>
      </div>

      <div v-if="engineersLoading" id="engineersLoadingBanner">
        <div class="spinnerBtn">
          <v-progress-circular
            :size="50"
            color="#979DA5;"
            indeterminate
          ></v-progress-circular>
          <div>Retrieving data about engineers...</div>
        </div>
      </div>
      <div
        v-else
        class="expert-section"
        v-for="(role, role_idx) in allTeams.matchRole"
        :key="role_idx"
      >
        <div>
          <div class="expert-title">
            {{ role }}
          </div>
          <div class="expert-cards">
            <div
              style="border: 0px solid rgb(234, 232, 232)"
              v-for="(item, item_idx) in allTeams.matchUser[role_idx]"
              :key="item_idx"
            >
              <div class="expert-card">
                <div class="expert-identity">
                  <div class="expert-blurred-name">Redacted Name</div>
                  <div class="expert-match-percentage">
                    {{ item.total_percentage.toFixed(2) }}% Match
                  </div>
                </div>

                <div class="expert-experience-label">
                  {{ item.user.work_experience_count }} year{{
                    item.user.work_experience_count !== 1 ? "s" : null
                  }}
                  of experience
                </div>

                <div class="expert-detail">
                  <img
                    id="expert-detail-img"
                    src="/img/Map-location.svg"
                    alt=""
                  />
                  {{
                    item.user.user_location
                      ? item.user.user_location
                      : "Unknown"
                  }}
                </div>
                <div class="expert-detail">
                  <img id="expert-detail-img" src="/img/Bag.svg" alt="" />
                  {{
                    item.user.work_experience[0] !== undefined
                      ? item.user.work_experience[0].organization.length > 21
                        ? item.user.work_experience[0].organization.slice(
                            0,
                            22
                          ) + "..."
                        : item.user.work_experience[0].organization
                      : "None"
                  }}
                </div>

                <div
                  class="expert-detail"
                  :style="`display:${hideList(
                    LanguageTool(item.user.language_tools) != 'Empty' &&
                      LanguageTool(item.user.language_tools) !== 'null' &&
                      LanguageTool(item.user.language_tools) !== '[]' &&
                      LanguageTool(item.user.language_tools) !== []
                      ? true
                      : false
                  )}`"
                >
                  <img id="expert-detail-img" src="/img/Stack.svg" alt="" />
                  {{ LanguageTool(item.user.language_tools) }}
                </div>
              </div>

              <div
                class="expert-detail"
                :style="`display:${hideList(
                  LanguageTool(item.user.team_interest) != 'Empty'
                    ? true
                    : false
                )}`"
              >
                <img id="expert-detail-img" src="/img/Shape_2.svg" alt="" />
                {{ LanguageTool(item.user.team_interest) }}
              </div>
              <!--  @click="EngineerDetailView(item)"  -->

              <div
                class="viewUser contentBlock"
                :style="`
                  text-align: right;
                  width: 100%;
                  border: 0px solid green;
                  margin-top: -40px;
                  font-size: 12px;
                  cursor: pointer;
                  color: #0781f2 !important;
                  padding-right:30px;
                `"
                :data-index="`${item_idx}`"
                :data-user="`${JSON.stringify(item)}`"
                :key="role_idx"
                :data-key="`${role_idx}`"
                :data-name="`${item.user.name}`"
                :data-image="`${
                  typeof item.user.profile_picture !== 'object'
                    ? 'https://grupa-media.s3-us-west-2.amazonaws.com/live/' +
                        item.user.profile_picture ||
                      '/img/onboarding/profile-avatar.svg'
                    : '/img/onboarding/profile-avatar.svg'
                }`"
                :data-location="`${
                  item.user.user_location ? item.user.user_location : 'Unknown'
                }`"
                :data-organisation="`${
                  item.user.work_experience[0] !== undefined
                    ? item.user.work_experience[0].organization
                    : 'None'
                }`"
              >
                Learn More
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="engineerDetailsModal"
      tabindex="-1"
      aria-labelledby="engineerDetailsModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="engineerDetailsModalLabel">
              Engineer Details
            </h5>

            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div
            class="modal-body"
            style="
              position: relative !important;
              min-height: 600px;
              /* padding: 15px; */
              padding-bottom: 300px;
              background: rgb(247, 247, 248);
            "
          >
            <p style="height: 600px">
              <ProfileData />
            </p>
          </div>
          <div class="modal-footer">
            <button
              style="margin: 10px"
              type="button"
              data-bs-dismiss="modal"
              class="btn btn-primary lightBorder"
              @click="handleProceed"
            >
              Contact Engineer
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="bottom-nav">
      <div id="bottom-nav-content">
        <div class="go-back-btn" @click="handleGoBack">
          <img src="/img/onboarding/go-back-icon.svg" alt="" />
          <span>Go Back</span>
        </div>
        <div id="proceed-button-container">
          <button type="button" @click="handleProceed" class="grupa-blue-btn">
            Get Full Access
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar/navbar";
import moment from "moment";

import mixPanel from "@/mixins/mixPanel";
import { getEnginnerforProject } from "@/api";
import ProfileData from "../selectTeam/ProfileData.vue";

export default {
  mixins: [mixPanel],
  components: {
    Navbar,
    ProfileData,
  },
  data: () => ({
    allTeams: {
      matchRole: [],
      matchUser: [],
    },
    learn_dialog: false,
    previousRoute: "project_workspace",
    engineersLoading: true,
  }),
  async mounted() {
    this.$nextTick(async () => {
      function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }

      function capitalizeData(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }

      function returnDate(objDate) {
        var month = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        var objDate = new Date(objDate);
        // console.log(objDate.getMonth())
        //console.log(objDate.getFullYear())
        return month[objDate.getMonth()] + " " + objDate.getFullYear();
      }

      function returnDateYear(objDate) {
        var objDate = new Date(objDate);
        // console.log(objDate.getMonth())
        //console.log(objDate.getFullYear())
        return objDate.getFullYear();
      }

      function compensationMethod(b) {
        let a = parseInt(b);
        if (a == null) {
          return "";
        }
        switch (a) {
          case 1:
            return "Cash Only";
          case 2:
            return "A mix of cash & equity";
          case 3:
            return "Equity only";
          case 4:
            return "I’m not sure yet";

          default:
            return "None";
        }
      }
      $("body").on("click", ".viewUser", async function () {
        var item = $(this).data("user");

        let index = $(this).attr("data-index");
        let user = $(this).attr("data-user");

        let key = $(this).attr("data-key");

        let location = $(this).attr("data-location");
        let name = $(this).attr("data-name");
        let organisation = $(this).attr("data-organisation");
        let image = $(this).attr("data-image");

        $(".addToTeam").attr("data-index", index);
        $(".addToTeam").attr("data-user", item.user.id);
        $(".addToTeam").attr("data-location", location);
        $(".addToTeam").attr("data-organisation", organisation);
        $(".addToTeam").attr("data-key", key);
        $(".addToTeam").attr("data-image", image);
        $(".addToTeam").attr("data-email", item.user.email);

        let imageUrl = "https://grupa-media.s3-us-west-2.amazonaws.com/live/";
        /*  let img =
          typeof item.user.profile_picture !== "object"
            ? imageUrl + item.user.profile_picture ||
              "/img/onboarding/profile-avatar.svg"
            : "/img/onboarding/profile-avatar.svg"; */

        let img = imageUrl + "img/onboarding/profile-avatar.svg";

        console.log(img);
        // $(".profileImage").attr("src", img);

        $(".profileLoader").fadeOut(() => {
          $(".profileData").fadeIn();
        });

        if (!$(".card-item", this).hasClass("selectedEngineer")) {
          // $(".addToTeam").fadeIn();

          if ($(this).find(".btn-unverified").css("display") == "none") {
            $(".addToTeam").fadeIn();
          } else {
            $(".addToTeam").fadeOut();
          }
        } else {
          $(".addToTeam").fadeOut();
        }

        // $(".user-name").html(item.user.name);
        $(".user-name").html("Scrambled Name");

        $(".experience").html(
          `${item.user.work_experience_count} year${
            item.user.work_experience_count > 0 ? "s" : ""
          } of experience`
        );
        $(".location").html(
          `${item.user.user_location ? item.user.user_location : "Unknown"}`
        );

        $(".organization_").html(
          `${
            item.user.work_experience[0] !== undefined
              ? item.user.work_experience[0].organization
              : "None"
          }`
        );

        let userCompensation = "";

        //alert(JSON.parse(item.user.compensation_plans || '[]'))

        item.user.compensation_plans !== null ||
          item.user.compensation_plans != null ||
          item.user.compensation_plans !== undefined;
        item.user.compensation_plans != undefined;
        item.user.compensation_plans !== "null" ||
        JSON.parse(item.user.compensation_plans || "[]").length < 1
          ? JSON.parse(item.user.compensation_plans || "[]").forEach((x, i) => {
              // console.log("compensation_" + x);
              userCompensation +=
                x == 4
                  ? ""
                  : `
                  <div class="investor_data">
                  <img src="/img/cash1.svg" />
                  <span class="mx-1 " >${compensationMethod(x)}</span>
                </div>`;
            })
          : null;

        if (JSON.parse(item.user.compensation_plans || "[]").length > 0) {
          $(".compensation_").html(
            `<div class="text-muted" style="font-size:11px; margin-bottom: -5px;">Compensation choice</div>${userCompensation}`
          );
          $(".compensation_hr, .compensation_").fadeIn();
        } else {
          $(".compensation_hr, .compensation_").fadeOut();
        }

        $(".matcher").html(`${item.total_percentage.toFixed(2)}% Match`);
        $(".ticker").html(
          `${item.user.name.split(" ")[0]} ticks the following boxes for you;`
        );
        $(".roleMatch").html(item.user.team_role);

        if (item.skill_match.length > 0) {
          $(".skillMatch").show();
          $(".skillMatch").html(
            `Proficient in ${item.skill_match
              .toString()
              .replaceAll(",", ", ")
              .replace(/,(?=[^,]*$)/, " & ")}`
          );
        } else {
          $(".skillMatch").hide();
        }

        if (item.platform_match.length > 0) {
          $(".platformMatch").show();
          $(".platformMatch").html(
            `Has experience in ${item.platform_match
              .toString()
              .replaceAll(",", ", ")
              .replace(/,(?=[^,]*$)/, " & ")}`
          );
        } else {
          $(".platformMatch").hide();
        }

        //console.log(item.industry_match)
        if (item.industry_match.length > 0) {
          $(".industryMatchMain").show();
          $(".industryMatch").html(
            `Is interested in ${item.industry_match
              .toString()
              .replaceAll(",", ", ")
              .replace(/,(?=[^,]*$)/, " & ")} industry`
          );
        } else {
          $(".industryMatchMain").hide();
        }

        // $(".aboutUser").html(`${item.user.bio}`);

        $(".aboutUser").html(
          `${
            item.user.bio !== undefined && item.user.bio !== null
              ? item.user.bio.length > 211
                ? item.user.bio.slice(0, 212) + "..."
                : item.user.bio
              : "None"
          }`
        );

        let userSkill = "";
        // console.log(item.user.language_tools)
        item.user.language_tools !== "null"
          ? JSON.parse(item.user.language_tools).forEach(
              (x, i) =>
                (userSkill += `<li data-v-e45522b2 class="flex-item skillData">${capitalizeFirstLetter(
                  x
                )}</li>`)
            )
          : (userSkill = `<li data-v-e45522b2 class="flex-item skillData">None</li>`);

        let work_experience = "";

        item.user.work_experience.forEach(
          (x, i) =>
            (work_experience += `
                <div data-v-e45522b2 class="my-3 experienceData ${
                  i % 2 ? "" : "exOdd"
                }" style="text-align: left; ">

                  <div data-v-e45522b2 class="exImage">
                     <img data-v-e45522b2
                    src="${
                      x.company_logo !== "" &&
                      x.company_logo !== null &&
                      typeof x.company_logo !== "object"
                        ? x.company_logo
                        : "/img/icon.svg"
                    }"
                    class="rounded-circle img-fluid exImage"
                    style=""
                  />

                  </div>
                  <div class="exJob" data-v-e45522b2>
                    <div class="exJobTop" data-v-e45522b2>
                      <div class="exJobTopTitle" data-v-e45522b2>${
                        x.organization
                      }</div>

                    <div class="exJobTopTitleSub" data-v-e45522b2>${
                      x.role
                    }</div>
                    </div>


                  <div class="exJobBottom" data-v-e45522b2>

                    <span class="exJobBottomData" data-v-e45522b2>
                     ${returnDate(x.start_date)} -  ${
              x.current_job == 1 ? "Present" : returnDate(x.end_date)
            } <span style='font-size:19px'>&nbsp;•&nbsp;</span> ${
              x.current_job == 1
                ? moment(x.start_date).fromNow().replace(" ago", "")
                : moment(x.start_date).format("dddd, MMMM Do YYYY") //.fromNow().replace(" ago", "")
            }
                    </span>
                  </div>
                  </div>

                </div>`)
        );

        let users_education = "";
        let users_education_count = 0;

        item.user.users_education.forEach((x, i) => {
          users_education += `
            <div data-v-e45522b2 class="educationData">
                   <div  data-v-e45522b2class="eduImage"><img data-v-e45522b2  src="/img/education.svg" class="eduImage-img"/></div>
                   <div data-v-e45522b2 class="eduData">
                     <div data-v-e45522b2 class="eduDataTop">
                       <div data-v-e45522b2 class="eduDataTopTitle">${capitalizeData(
                         x.institution
                       )}</div>
                       <div data-v-e45522b2 class="eduDataTopTitleSub">${
                         x.degree_title
                       }</div>
                     </div>
                     <div data-v-e45522b2 class="eduDataBottom">${returnDateYear(
                       x.start_date
                     )} - ${returnDateYear(x.end_date)}</div>
                   </div>
                 </div>


            `;
          users_education_count += i;
        });

        //work_experience

        $(".flex-container").html(userSkill);

        $(".work_experienceData").html(work_experience);

        $(".educationDataMain").html(users_education);

        $(".work_viewMore, .work_viewLess").hide();
        $(".expert-blurred-name2").css({
          "text-shadow": "0 0 6px black",
          color: "transparent",
        });

        $(".modal-body").css({
          position: "relative",

          "overflow-y": "auto",
        });

        $(".profileImage").css({
          filter: "blur(8px)",
          "-webkit-filter": "blur(8px)",
        });

        $("#engineerDetailsModal").modal("show");

        // text-shadow: 0 0 6px black;
        // color: transparent;
      });
      await this.fetchMatchingEngineers({
        skill_check: true,
        platforms_check: true,
        industry_check: true,
      });
    });
  },
  beforeRouteEnter(_to, from, next) {
    next((vm) => {
      vm.previousRoute = from.name || "project_workspace";
    });
  },
  methods: {
    EngineerDetailView(data) {
      console.log(data);

      $("#engineerDetailsModal").modal("show");
    },
    handleGoBack() {
      this.$router.push({
        name: this.previousRoute,
      });
      this.mixPanelTrack({
        event: `ReturnTo_${this.previousRoute}`,
      });
    },

    handleProceed() {
      this.$router.push({
        name: "reserve_team",
      });
      this.mixPanelTrack({
        event: "GoToPriorityListFromPreview",
      });
    },

    hideList(data) {
      return data === true ? "block" : "none";
    },

    LanguageTool(text) {
      if (text) {
        let data = text
          .replaceAll('["', "")
          .replaceAll('","', ", ")
          .replaceAll('"]', "")
          .slice(0, 22);

        if (data.length > 21) {
          return data + "...";
        } else {
          return data + "";
        }
      } else {
        return "Empty";
      }
    },

    async fetchMatchingEngineers(params) {
      let projectId = this.$route.params.id;

      await getEnginnerforProject(projectId, params)
        .then((response) => {
          this.allTeams.matchRole = response.data.engineer[0].matchRole;
          this.allTeams.matchUser = [];

          let matchUser = response.data.engineer[0].matchUser;
          for (let i in matchUser) {
            this.allTeams.matchUser.push(matchUser[i].slice(0, 3));
          }
        })
        .then(() => {
          this.engineersLoading = false;
        });
    },
  },
};
</script>

<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}

#navbar-container {
  position: fixed;
  width: 100vw;
  z-index: 100;
}

#content-wrapper {
  margin: auto;
  margin-top: 100px;
  width: 867px;
  margin-bottom: 128px;
}

#info-section {
  margin: auto;
  margin-top: 100px;
  background: rgba(7, 129, 242, 0.05);
  border-radius: 4px;
  height: 116px;
  display: flex;
  padding-top: 34px;
  padding-bottom: 34px;
  padding-left: 24px;
  padding-right: 24px;
}

#info-section-lock-div {
  background-color: white;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}

#info-section-lock-img {
  width: 28px;
  height: 28px;
}

#info-section-content {
  flex-direction: column;
}

#info-section-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  vertical-align: top;
}

#info-section-title span {
  font-weight: 700;
  font-size: 16px;
  line-height: 8px;
  text-align: left;
  vertical-align: top;
}

#info-section-marked-content-section {
  display: flex;
  flex-direction: row;
  margin-top: 11px;
}

.info-section-marked-content {
  display: flex;
  flex-direction: row;

  height: 16px;
  vertical-align: bottom;
}

.info-section-marked-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
  color: #6f7680;
  margin-right: 16px;
}

.info-section-mark {
  margin-right: 8px;
}

#info-section-button-container {
  padding-left: 55px;
}

#banner-wrapper {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
}

#banner-title {
  font-weight: 700;
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
  text-align: left;
}

#banner-content {
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  color: #979da5;
}

.expert-section {
  display: flex;
  margin-top: 48px;
  flex-direction: column;
}

.expert-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 145%;
  color: #1b1e22;
  text-align: left;
}

.expert-cards {
  display: flex;
  flex-direction: row;
}

.expert-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 10px;
  width: 273px;
  height: 236px;
  background: #fdfdfd;
  border: 1px solid #e4e5e7;
  border-radius: 8px;
  margin-top: 18px;
}

.expert-card:not(:last-child) {
  margin-right: 12px;
}

.expert-card:not(:first-child) {
  margin-left: 12px;
}

.expert-identity {
  display: flex;
  flex-direction: row;
}

.expert-blurred-name {
  text-shadow: 0 0 6px black;
  color: transparent;
}

.expert-match-percentage {
  text-align: right;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 145%;
  color: #1fa564;
  margin-left: 20px;
}

.expert-experience-label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  color: #979da5;
}

.expert-detail {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 145%;
  color: #6f7680;
}

.expert-detail img {
  margin-right: 12px;
}

.go-back-btn {
  cursor: pointer;
}

.go-back-btn span {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 145%;
  color: #0781f2;
  margin-left: 5px;
}

#bottom-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #e4e5e7;
  width: 100%;
  height: 64px;
  background: #ffffff;
}

#bottom-nav-content {
  width: 867px;
  justify-content: right;
  display: flex;
  align-items: center;
}

#proceed-button-container {
  margin-left: 40px;
}

#learn-more-section {
  box-shadow: 2px 7px 30px rgba(33, 33, 33, 0.15);
  border-radius: 4px;
  background: #ffffff;
  width: 550px;
  height: 500px;
  text-align: left;
  font-size: 14px;
  line-height: 150%;
  color: #1b1e22;
  position: relative;
}

#learn-more-header {
  font-weight: 500;
  background: #f7f7f8;
  box-shadow: 0px 1px 2px rgba(30, 27, 34, 0.1);
  border-radius: 4px 4px 0px 0px;
  height: 50px;
  padding-top: 14px;
  padding-left: 24px;
}

#learn-more-content {
  padding-left: 24px;
  padding-right: 24px;
}

.learn-more-info {
  margin-top: 24px;
}

.learn-more-question {
  font-weight: 700;
}

.learn-more-answer {
  margin-top: 12px;
}

#learn-more-bottom-panel {
  position: absolute;
  bottom: 12px;
  right: 24px;
}

#learn-more-bottom-panel button:not(:last-child) {
  margin-right: 16px;
}

#engineersLoadingBanner {
  margin-top: 25%;
  color: #979da5;
}
</style>
