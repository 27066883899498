var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "body" } },
    [
      _c("div", { attrs: { id: "navbar-container" } }, [_c("Navbar")], 1),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "550" },
          model: {
            value: _vm.learn_dialog,
            callback: function ($$v) {
              _vm.learn_dialog = $$v
            },
            expression: "learn_dialog",
          },
        },
        [
          _c("div", { attrs: { id: "learn-more-section" } }, [
            _c("div", { attrs: { id: "learn-more-header" } }, [
              _vm._v("Get Full Access"),
            ]),
            _c("div", { attrs: { id: "learn-more-content" } }, [
              _c("div", { staticClass: "learn-more-info" }, [
                _c("div", { staticClass: "learn-more-question" }, [
                  _vm._v("Why?"),
                ]),
                _c("div", { staticClass: "learn-more-answer" }, [
                  _vm._v(
                    "\n            Our expert marketplace is restricted to the general public. This\n            is mainly to protect our experts’ privacy and their time.\n          "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "learn-more-info" }, [
                _c("div", { staticClass: "learn-more-question" }, [
                  _vm._v("How do I unlock full access?"),
                ]),
                _c("div", { staticClass: "learn-more-answer" }, [
                  _vm._v(
                    "\n            Pay a one-time fee of $500 to unlock full access to the\n            marketplace, find a match and meet with the experts immediately.\n            We will provide you with priority support and refund you if we are\n            unable to find an expert that fit your needs.\n          "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "learn-more-info" }, [
                _c("div", { staticClass: "learn-more-question" }, [
                  _vm._v("What can I do otherwise?"),
                ]),
                _c("div", { staticClass: "learn-more-answer" }, [
                  _vm._v(
                    "\n            If you are not ready to proceed at this time, you can add members\n            to your virtual team and join the waitlist. We will reach out to\n            you if any expert indicates interest in your product.\n          "
                  ),
                ]),
              ]),
            ]),
            _c("div", { attrs: { id: "learn-more-bottom-panel" } }, [
              _c(
                "button",
                {
                  staticClass: "grupa-grey-black-btn",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.learn_dialog = false
                    },
                  },
                },
                [_vm._v("\n          Close\n        ")]
              ),
              _c(
                "button",
                {
                  staticClass: "grupa-blue-btn",
                  attrs: { type: "button" },
                  on: { click: _vm.handleProceed },
                },
                [_vm._v("\n          Get Full Access\n        ")]
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        { attrs: { id: "content-wrapper" } },
        [
          _c("div", { attrs: { id: "info-section" } }, [
            _vm._m(0),
            _vm._m(1),
            _c("div", { attrs: { id: "info-section-button-container" } }, [
              _c(
                "button",
                {
                  staticClass: "grupa-blue-btn",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.learn_dialog = true
                    },
                  },
                },
                [_vm._v("\n          Learn More\n        ")]
              ),
            ]),
          ]),
          _vm._m(2),
          _vm.engineersLoading
            ? _c("div", { attrs: { id: "engineersLoadingBanner" } }, [
                _c(
                  "div",
                  { staticClass: "spinnerBtn" },
                  [
                    _c("v-progress-circular", {
                      attrs: { size: 50, color: "#979DA5;", indeterminate: "" },
                    }),
                    _c("div", [_vm._v("Retrieving data about engineers...")]),
                  ],
                  1
                ),
              ])
            : _vm._l(_vm.allTeams.matchRole, function (role, role_idx) {
                return _c(
                  "div",
                  { key: role_idx, staticClass: "expert-section" },
                  [
                    _c("div", [
                      _c("div", { staticClass: "expert-title" }, [
                        _vm._v("\n          " + _vm._s(role) + "\n        "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "expert-cards" },
                        _vm._l(
                          _vm.allTeams.matchUser[role_idx],
                          function (item, item_idx) {
                            return _c(
                              "div",
                              {
                                key: item_idx,
                                staticStyle: {
                                  border: "0px solid rgb(234, 232, 232)",
                                },
                              },
                              [
                                _c("div", { staticClass: "expert-card" }, [
                                  _c(
                                    "div",
                                    { staticClass: "expert-identity" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "expert-blurred-name" },
                                        [_vm._v("Redacted Name")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "expert-match-percentage",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                item.total_percentage.toFixed(2)
                                              ) +
                                              "% Match\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "expert-experience-label" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            item.user.work_experience_count
                                          ) +
                                          " year" +
                                          _vm._s(
                                            item.user.work_experience_count !==
                                              1
                                              ? "s"
                                              : null
                                          ) +
                                          "\n                of experience\n              "
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "expert-detail" }, [
                                    _c("img", {
                                      attrs: {
                                        id: "expert-detail-img",
                                        src: "/img/Map-location.svg",
                                        alt: "",
                                      },
                                    }),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          item.user.user_location
                                            ? item.user.user_location
                                            : "Unknown"
                                        ) +
                                        "\n              "
                                    ),
                                  ]),
                                  _c("div", { staticClass: "expert-detail" }, [
                                    _c("img", {
                                      attrs: {
                                        id: "expert-detail-img",
                                        src: "/img/Bag.svg",
                                        alt: "",
                                      },
                                    }),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          item.user.work_experience[0] !==
                                            undefined
                                            ? item.user.work_experience[0]
                                                .organization.length > 21
                                              ? item.user.work_experience[0].organization.slice(
                                                  0,
                                                  22
                                                ) + "..."
                                              : item.user.work_experience[0]
                                                  .organization
                                            : "None"
                                        ) +
                                        "\n              "
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "expert-detail",
                                      style:
                                        "display:" +
                                        _vm.hideList(
                                          _vm.LanguageTool(
                                            item.user.language_tools
                                          ) != "Empty" &&
                                            _vm.LanguageTool(
                                              item.user.language_tools
                                            ) !== "null" &&
                                            _vm.LanguageTool(
                                              item.user.language_tools
                                            ) !== "[]" &&
                                            _vm.LanguageTool(
                                              item.user.language_tools
                                            ) !== []
                                            ? true
                                            : false
                                        ),
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          id: "expert-detail-img",
                                          src: "/img/Stack.svg",
                                          alt: "",
                                        },
                                      }),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.LanguageTool(
                                              item.user.language_tools
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "expert-detail",
                                    style:
                                      "display:" +
                                      _vm.hideList(
                                        _vm.LanguageTool(
                                          item.user.team_interest
                                        ) != "Empty"
                                          ? true
                                          : false
                                      ),
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "expert-detail-img",
                                        src: "/img/Shape_2.svg",
                                        alt: "",
                                      },
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.LanguageTool(
                                            item.user.team_interest
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    key: role_idx,
                                    staticClass: "viewUser contentBlock",
                                    style:
                                      "\n                text-align: right;\n                width: 100%;\n                border: 0px solid green;\n                margin-top: -40px;\n                font-size: 12px;\n                cursor: pointer;\n                color: #0781f2 !important;\n                padding-right:30px;\n              ",
                                    attrs: {
                                      "data-index": "" + item_idx,
                                      "data-user": "" + JSON.stringify(item),
                                      "data-key": "" + role_idx,
                                      "data-name": "" + item.user.name,
                                      "data-image":
                                        "" +
                                        (typeof item.user.profile_picture !==
                                        "object"
                                          ? "https://grupa-media.s3-us-west-2.amazonaws.com/live/" +
                                              item.user.profile_picture ||
                                            "/img/onboarding/profile-avatar.svg"
                                          : "/img/onboarding/profile-avatar.svg"),
                                      "data-location":
                                        "" +
                                        (item.user.user_location
                                          ? item.user.user_location
                                          : "Unknown"),
                                      "data-organisation":
                                        "" +
                                        (item.user.work_experience[0] !==
                                        undefined
                                          ? item.user.work_experience[0]
                                              .organization
                                          : "None"),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              Learn More\n            "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ]),
                  ]
                )
              }),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: {
            id: "engineerDetailsModal",
            tabindex: "-1",
            "aria-labelledby": "engineerDetailsModalLabel",
            "aria-hidden": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog modal-lg" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(3),
              _c(
                "div",
                {
                  staticClass: "modal-body",
                  staticStyle: {
                    position: "relative !important",
                    "min-height": "600px",
                    "/* padding": "15px",
                    "*/\n            padding-bottom": "300px",
                    background: "rgb(247, 247, 248)",
                  },
                },
                [
                  _c(
                    "p",
                    { staticStyle: { height: "600px" } },
                    [_c("ProfileData")],
                    1
                  ),
                ]
              ),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary lightBorder",
                    staticStyle: { margin: "10px" },
                    attrs: { type: "button", "data-bs-dismiss": "modal" },
                    on: { click: _vm.handleProceed },
                  },
                  [_vm._v("\n            Contact Engineer\n          ")]
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _c("div", { attrs: { id: "bottom-nav" } }, [
        _c("div", { attrs: { id: "bottom-nav-content" } }, [
          _c(
            "div",
            { staticClass: "go-back-btn", on: { click: _vm.handleGoBack } },
            [
              _c("img", {
                attrs: { src: "/img/onboarding/go-back-icon.svg", alt: "" },
              }),
              _c("span", [_vm._v("Go Back")]),
            ]
          ),
          _c("div", { attrs: { id: "proceed-button-container" } }, [
            _c(
              "button",
              {
                staticClass: "grupa-blue-btn",
                attrs: { type: "button" },
                on: { click: _vm.handleProceed },
              },
              [_vm._v("\n          Get Full Access\n        ")]
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "info-section-lock-div" } }, [
      _c("img", {
        attrs: { id: "info-section-lock-img", src: "/img/Locked.svg", alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "info-section-content" } }, [
      _c("div", { attrs: { id: "info-section-title" } }, [
        _c("span", [
          _vm._v("Get full access to the Expert marketplace for $500"),
        ]),
      ]),
      _c("div", { attrs: { id: "info-section-marked-content-section" } }, [
        _c("div", { staticClass: "info-section-marked-content" }, [
          _c("img", {
            staticClass: "info-section-mark",
            attrs: { src: "/img/mark_green.svg", alt: "" },
          }),
          _c("p", { staticClass: "info-section-marked-text" }, [
            _vm._v("Meet experts immediately"),
          ]),
        ]),
        _c("div", { staticClass: "info-section-marked-content" }, [
          _c("img", {
            staticClass: "info-section-mark",
            attrs: { src: "/img/mark_green.svg", alt: "" },
          }),
          _c("p", { staticClass: "info-section-marked-text" }, [
            _vm._v("Get Priority support"),
          ]),
        ]),
        _c("div", { staticClass: "info-section-marked-content" }, [
          _c("img", {
            staticClass: "info-section-mark",
            attrs: { src: "/img/mark_green.svg", alt: "" },
          }),
          _c("p", { staticClass: "info-section-marked-text" }, [
            _vm._v("\n              Refundable if not satisfied\n            "),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "banner-wrapper" } }, [
      _c("div", { attrs: { id: "banner-title" } }, [
        _vm._v("Preview your potential team"),
      ]),
      _c("div", { attrs: { id: "banner-content" } }, [
        _vm._v(
          "\n        We have curated a list of experts that best fit your needs. Take a\n        look at the potential team you could build.\n      "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        {
          staticClass: "modal-title",
          attrs: { id: "engineerDetailsModalLabel" },
        },
        [_vm._v("\n            Engineer Details\n          ")]
      ),
      _c("button", {
        staticClass: "btn-close",
        attrs: {
          type: "button",
          "data-bs-dismiss": "modal",
          "aria-label": "Close",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }